import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class AuthGroupAPIService {
  constructor() {
    this.search = '';
    this.firm = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getAuthGroups(router) {
    const url = '/api/authgroups';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getAuthGroupHosts(router) {
    const url = `/api/authgroups/hosts`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getActiveAuthGroups(router) {
    const url = '/api/authgroups?active=true';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getAuthGroupsById(id, router) {
    const url = `/api/authgroups/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getAuthGroupsWithScope(id, router) {
    const url = `/api/authgroups/${id}/scopes`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getAuthGroupsWithUsers(id, router) {
    const url = `/api/authgroups/${id}/linked_users`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addAuthGroup(router, parms) {
    const url = `/api/authgroups`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  deleteAuthGroup(router, id) {
    const url = `/api/authgroups/${id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateAuthGroup(router, id, parms) {
    const url = `/api/authgroups/${id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  searchAuthGroups(router, search) {
    const url = `/api/authgroups?search=${search}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addUserToAuthGroups(userid, groups, router) {
    const url = `/api/authgroups/${userid}/user`;
    return this.CommonAPIService.postCall(url, {groups}, router);
  }

  removeUserFromAuthGroup(router, userid, groupid) {
    const url = `/api/authgroups/${groupid}/users/${userid}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  addScopeToAuthGroup(router, scopeid, groupid) {
    const url = `/api/authgroups/${groupid}/scopes/${scopeid}`;
    return this.CommonAPIService.putCall(url, null, router);
  }

  removeScopeFromAuthGroup(router, scopeid, groupid) {
    const url = `/api/authgroups/${groupid}/scopes/${scopeid}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  getLinkedAuthgroupScopes(id, router) {
    const url = `/api/authgroups/${id}/linked_scopes`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getLinkedAuthgroupsCommandLookups(id, router) {
    const url = `/api/authgroups/${id}/linked_command_lookups`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getLinkedAuthgroupHosts(id, router) {
    const url = `/api/authgroups/${id}/linked_hosts`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkAuthgroupToScopes(id, scope_ids, router) {
    const url = `/api/authgroups/${id}/${scope_ids}/link_scopes`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkAuthgroupToCommandLookups(id, command_lookup_ids, router) {
    const url = `/api/authgroups/${id}/${command_lookup_ids}/link_command_lookups`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkAuthgroupToHosts(id, host_ids, router) {
    const url = `/api/authgroups/${id}/${host_ids}/link_hosts`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkAuthgroupToUsers(parms, router) {
    const url = `/api/authgroups/link_users`;
    return this.CommonAPIService.postCall(url, parms, router);
  }
}

export default AuthGroupAPIService;
